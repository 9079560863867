import React from "react";
import iconWifi from "../../assets/images/icon-wifi.svg";
import iconWifiSpace from "../../assets/images/icon-wifi-space.svg";
import iconMoodlight from "../../assets/images/icon-moodlight.svg";
import iconPrivateDriver from "../../assets/images/icon-private-driver.svg";
import iconliveTv from "../../assets/images/icon-live-tv.svg";
import iconRestroom from "../../assets/images/icon-restroom.svg";
import iconDesk from "../../assets/images/icon-desk.svg";
import iconSoundSystem from "../../assets/images/icon-sound-system.svg";
import miniFridge from "../../assets/images/icon-mini-fridge.svg";
import iconPresentation from "../../assets/images/icon-presentation.svg";
import iconOffice from "../../assets/images/icon-office-supply.svg";
import iconAppleTV from "../../assets/images/icon-apple-tv.svg";
import iconProjector from "../../assets/images/icon-projector.svg";
import iconWhiteboard from "../../assets/images/icon-whiteboard.svg";
import iconAudio from "../../assets/images/icon-audio.svg";
import iconManager from "../../assets/images/icon-manager.svg";

const ServiceIcon = ({ alt, label, icon }) => {
  const icons = {
    iconWifi,
    iconWifiSpace,
    iconMoodlight,
    iconPrivateDriver,
    iconliveTv,
    iconRestroom,
    iconDesk,
    iconSoundSystem,
    miniFridge,
    iconPresentation,
    iconOffice,
    iconAppleTV,
    iconProjector,
    iconWhiteboard,
    iconAudio,
    iconManager
  };
  return (
    <div className="service-icon">
      <img src={icons[icon]} alt={alt} />
      <p className={icon === "iconWifi" ? "icon-wifi" : ""}>{label}</p>
    </div>
  );
};

export default ServiceIcon;
