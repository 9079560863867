import React from "react";
import { Label, Input, FormFeedback, CustomInput } from "reactstrap";

export const InputField = ({ name, label, max, type = "text" }) => (
  <div className="field-group">
    <label htmlFor={name}>{label}</label>
    <input name={name} type={type} max={max || ""} min="1" />
  </div>
);

export const InputText = ({
  name,
  id,
  label,
  placeholder = "",
  formFeedback,
  type = "text",
  valid = false,
  invalid = false,
  onChange,
  value
}) => (
  <span>
    <Label for={name}>{label}</Label>
    <Input
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      onChange={e => onChange(e)}
      valid={valid}
      invalid={invalid}
      value={value}
    />
    <FormFeedback valid={valid} invalid={invalid}>
      {formFeedback}
    </FormFeedback>
  </span>
);

export const InputNumber = ({
  id,
  name,
  label,
  formFeedback,
  valid = false,
  invalid = false,
  onChange,
  value
}) => (
  <span>
    <Label for={name}>{label}</Label>
    <Input
      type="number"
      name={name}
      id={id}
      valid={valid}
      invalid={invalid}
      onChange={e => onChange(e)}
      value={value}
    />
    <FormFeedback valid={valid} invalid={invalid}>
      {formFeedback}
    </FormFeedback>
  </span>
);

export const SelectState = ({ states, name, id, onChange, value }) => (
  <span>
    <Label for="exampleCustomSelect">Select State</Label>
    <CustomInput
      id={id}
      type="select"
      name={name}
      style={{ marginTop: 0, boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.1)" }}
      onChange={e => onChange(e)}
      value={value}
    >
      <option value="">Select</option>
      {states.map((item, key) => (
        <option value={item} key={key}>
          {item}
        </option>
      ))}
    </CustomInput>
  </span>
);

export const SelectDateTime = ({
  id,
  name,
  type = "date",
  label,
  onChange,
  value
}) => (
  <span>
    <Label for={name}>{label}</Label>
    <Input
      id={id}
      type={type}
      name={name}
      onChange={e => onChange(e)}
      value={value}
    />
  </span>
);
