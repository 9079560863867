import React from "react";
import arrowLeft from "../../assets/images/arrow-left.svg";
import arrowRight from "../../assets/images/arrow-right.svg";
const PrevArrow = ({ className, onClick }) => (
  <img
    src={arrowLeft}
    className={className}
    onClick={onClick}
    alt="nav icons"
  />
);
const NextArrow = ({ className, onClick }) => (
  <img
    src={arrowRight}
    className={className}
    onClick={onClick}
    alt="nav icons"
  />
);
export const settings = () => ({
  dots: true,
  //autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});
