import React from "react";
import logo from "../../assets/images/human-elevation-logo.png";

const Header = ({ title }) => (
  <header>
    <a href="#0">
      <img id="logo" src={logo} alt="Human-Elevation" />
    </a>
    <h1 id="title">HUMAN ELEVATION</h1>
    <h2>{title}</h2>
  </header>
);

export default Header;
