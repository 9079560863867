import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, Nav } from "reactstrap";
import "./navbar.css";

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <Navbar color="light" light expand="md">
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {/*
            <NavItem>
              <Link className="nav-link" to="/he-amenities">
                Spaces
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/he-amenities/transportation">
                Transportation
              </Link>
            </NavItem>
            */}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
export default Navigation;
