import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Button } from "reactstrap";
import { Message, Loader } from "../../components/modal/modal";
import "./styles.css";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      loading: false,
      success: false,
      serverError: false,
    };
  }

  async submit(ev) {
    this.setState({ loading: true });
    let { token } = await this.props.stripe.createToken({
      name: this.props.customer,
    });
    await fetch(
      `http://localhost:9000/charge-downpayment/${this.props.bookingId}`,
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: token.id,
      }
    ).then((data) => {
      if (data.ok) return this.setState({ loading: false, success: true });

      return this.setState({
        success: false,
        loading: false,
        serverError: true,
      });
    });
  }

  render() {
    return (
      <div className="checkout">
        {this.state.loading && <Loader />}
        {this.state.success && (
          <Message
            title="Thank you!"
            message="We have received your downpayment."
            button="Ok"
            onClick={() => this.setState({ success: false })}
          />
        )}
        {this.state.serverError && (
          <Message
            title="Error!"
            message="There was an error with the server, please try again later..."
            button="back"
            onClick={() =>
              this.setState({ success: false, serverError: false })
            }
          />
        )}
        <h6>Would you like to complete th down payment?</h6>
        <CardElement />
        <Button onClick={this.submit}>Purchase</Button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
