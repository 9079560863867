import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Input,
  InputGroupAddon
} from "reactstrap";
import { Message, Loader } from "../../components/modal/modal";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./home.css";
import { urlParamsGet } from "../../utils";
import { getBookings, updateBooking } from "../../utils/db";

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: {
        dropoffLocation: {},
        email: "",
        name: "",
        numberGuest: "",
        phone: "",
        pickupLocation: {},
        specialNeeds: "",
        stopsQty: []
      },
      taxRate: 0.18,
      cost: 0,
      success: false,
      loading: false
    };
  }

  handleChange = evt => {
    if (evt.target.name === "taxRate") {
      return this.setState({
        [evt.target.name]: parseFloat(evt.target.value / 100).toFixed(2)
      });
    }
    this.setState({
      [evt.target.name]: parseFloat(evt.target.value).toFixed(2)
    });
  };

  onSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    const formData = this.state.bookings;
    formData.cost = parseInt(this.state.cost);
    formData.balance = parseInt(this.state.cost);
    formData.tax = parseFloat(this.state.cost * this.state.taxRate).toFixed(2);
    formData.totalCost = (
      parseFloat(formData.cost) + parseFloat(formData.tax)
    ).toFixed(2);
    formData.downpayment = parseFloat(formData.totalCost / 2).toFixed(2);
    formData.bookingId = this.state.bookingId;
    formData.status = "waiting for downpayment";

    updateBooking(this.state.bookingId, formData).then(async () => {
      try {
        const response = await fetch(
          "https://impactrepublic.com/he-amenities-invoice.php",

          {
            method: "POST",
            body: JSON.stringify(formData)
          }
        );
        await response.json();
        this.setState({ success: true, loading: false });
      } catch (error) {
        this.setState({ success: false, loading: false });
      }
    });
  };
  componentDidMount() {
    const params = urlParamsGet(this.props.location.search);
    this.setState({ bookingId: params.id });
    getBookings(params.id).then(doc => this.setState({ bookings: doc.data() }));
  }
  render() {
    return (
      <main>
        {this.state.loading && <Loader />}
        {this.state.success && (
          <Message
            title="Invoice Sent!"
            message="Customer will receive the invoice with the amount."
            button="back"
            onClick={() => this.setState({ success: false })}
          />
        )}
        <Header title="LUXURY MERCEDES SPRINTER" />
        <Container>
          <div className="payment-form-container">
            <Row>
              <Col md="3" />
              <Col md="6">
                <form id="cost-form" onSubmit={this.onSubmit}>
                  <h4>Customer's information:</h4>
                  <ul>
                    <li>
                      <strong>Name:</strong> {this.state.bookings.name}
                    </li>
                    <li>
                      <strong>Email:</strong> {this.state.bookings.email}
                    </li>
                    <li>
                      <strong>Phone:</strong> {this.state.bookings.phone}
                    </li>
                    <li>
                      <strong>Guests:</strong> {this.state.bookings.numberGuest}{" "}
                      {this.state.bookings.numberGuest > 1
                        ? " people"
                        : " person"}{" "}
                    </li>
                  </ul>

                  <h4>Booking information:</h4>
                  <h6>Pickup location</h6>
                  <ul>
                    <li>
                      <strong>Address:</strong>{" "}
                      {`${this.state.bookings.pickupLocation.address}, ${this.state.bookings.pickupLocation.city}. ${this.state.bookings.pickupLocation.state} ${this.state.bookings.pickupLocation.zipcode}`}
                    </li>
                    <li>
                      <strong>Date/Time: </strong>{" "}
                      {`${this.state.bookings.pickupLocation.date} at ${this.state.bookings.pickupLocation.time}`}
                    </li>
                  </ul>
                  <h6>Dropoff location</h6>

                  <ul>
                    <li>
                      <strong>Address:</strong>{" "}
                      {`${this.state.bookings.dropoffLocation.address}, ${this.state.bookings.dropoffLocation.city}. ${this.state.bookings.dropoffLocation.state} ${this.state.bookings.dropoffLocation.zipcode}`}
                    </li>
                    <li>
                      <strong>Date/Time: </strong>{" "}
                      {`${this.state.bookings.dropoffLocation.date} at ${this.state.bookings.dropoffLocation.time}`}
                    </li>
                  </ul>

                  <h4>Additional stops:</h4>
                  {this.state.bookings.stopsQty.map((stop, i) => (
                    <section key={i}>
                      <h6>Stop {i + 1}</h6>
                      <ul>
                        <li>
                          <strong>Address:</strong>{" "}
                          {`${stop.address}, ${stop.city}. ${stop.state} ${stop.zipcode}`}
                        </li>
                        <li>
                          <strong>Date/Time: </strong>{" "}
                          {`${stop.date} at ${stop.time}`}
                        </li>
                      </ul>
                    </section>
                  ))}
                  <Col md={5}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                      <Input
                        name="cost"
                        placeholder="Amount"
                        min={0}
                        max={10000}
                        type="number"
                        step="1"
                        onChange={this.handleChange}
                      />
                      <InputGroupAddon addonType="append">.00</InputGroupAddon>
                    </InputGroup>
                    <br />
                    <InputGroup>
                      <Input
                        name="taxRate"
                        placeholder="Tax-rate (default 18%)"
                        min={0}
                        max={100}
                        type="number"
                        step="1"
                        onChange={this.handleChange}
                      />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                    <Button
                      className="button-he"
                      style={{ float: "none" }}
                      type="submit"
                    >
                      Confirm
                    </Button>
                  </Col>
                </form>
              </Col>
            </Row>
          </div>
        </Container>
        <Footer />
      </main>
    );
  }
}

export default Payments;
