import React from "react";
import Transportation from "../home/home";
import Payments from "../payments";
import Checkout from "../checkout/checkout";
import Navigation from "../../components/nav";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "../../assets/styles/main.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Spaces from "../spaces/spaces";

const App = () => {
  return (
    <Router>
      <main>
        <Navigation />
        <Route path="/" exact component={Transportation} />
        <Route path="/he-amenities/payments" exact component={Payments} />
        <Route path="/he-amenities/downpayment" exact component={Checkout} />
        {/*<Route path="/he-amenities" exact component={Spaces} />*/}
      </main>
    </Router>
  );
};
export default App;
