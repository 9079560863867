import React from "react";
import Slider from "react-slick";
import { settings } from "./config";
import "./slider.css";
import sprinter1 from "../../assets/images/sprinter/he-sprinter-1.jpg";
import sprinter2 from "../../assets/images/sprinter/he-sprinter-2.jpg";
import sprinter3 from "../../assets/images/sprinter/he-sprinter-3.jpg";
import sprinter4 from "../../assets/images/sprinter/he-sprinter-4.jpg";
import sprinter5 from "../../assets/images/sprinter/he-sprinter-5.jpg";
import sprinter6 from "../../assets/images/sprinter/he-sprinter-6.jpg";
import sprinter7 from "../../assets/images/sprinter/he-sprinter-7.jpg";
import sprinter8 from "../../assets/images/sprinter/he-sprinter-8.jpg";
import sprinter9 from "../../assets/images/sprinter/he-sprinter-9.jpg";

const Carousel = () => {
  const items = [
    sprinter1,
    sprinter2,
    sprinter3,
    sprinter4,
    sprinter5,
    sprinter6,
    sprinter7,
    sprinter8,
    sprinter9
  ];

  return (
    <div className="carousel-wrapper">
      <Slider {...settings()}>
        {items.map((item, key) => (
          <div key={key}>
            <img src={item} alt="carousel-item" />
          </div>
        ))}
      </Slider>
      <section>
        <h3 className="d-none d-sm-block">
          Elevating Business &amp; <br /> Life… On The Go
        </h3>
        <h3 className="d-block d-sm-none">
          Elevating Business &amp; Life… On The Go
        </h3>
      </section>
    </div>
  );
};
export default Carousel;
