import React, { Component } from "react";
import Header from "../../components/header";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  FormGroup,
  Label,
  Input,
  Table
} from "reactstrap";
import { urlParamsGet } from "../../utils";
import { getBookings } from "../../utils/db";
import "./styles.css";
import sprinter from "../../assets/images/sprinter/he-sprinter-1.jpg";

import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "../../components/checkoutForm";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: {
        dropoffLocation: "",
        email: "",
        name: "",
        numberGuest: "",
        phone: "",
        pickupLocation: {},
        specialNeeds: "",
        stopsQty: [],
        agreement: false
      },
      bookingId: ""
    };
  }
  componentDidMount() {
    const params = urlParamsGet(this.props.location.search);
    this.setState({ bookingId: params.id });
    getBookings(params.id).then(doc => this.setState({ bookings: doc.data() }));
  }
  trim = str => {
    const arr = str.split("");
    const start = arr.slice(0, 4).join("");
    const end = arr.slice(arr.length - 8, arr.length).join("");
    return start + " ...... " + end;
  };
  pluralizePeople = number =>
    parseInt(number) > 1 ? `${number} People` : `${number} Person`;

  render() {
    return (
      <StripeProvider apiKey="pk_test_TxgAvpq6wfVIrW2AXwR90OKA00KLC1evnw">
        <div className="checkout-page">
          <Header title="" />
          <Container>
            <Row>
              <Col md="8">
                <h2>Booking details:</h2>
                <ListGroup>
                  <ListGroupItem>
                    <strong>Name:</strong> {this.state.bookings.name}
                  </ListGroupItem>
                  <ListGroupItem>
                    <strong>Email:</strong>{" "}
                    {this.trim(this.state.bookings.email)}
                  </ListGroupItem>
                  <ListGroupItem>
                    <strong>Phone number:</strong>
                    {" ...... "}
                    {this.state.bookings.phone
                      .split("")
                      .slice(
                        this.state.bookings.phone.length - 4,
                        this.state.bookings.phone.length
                      )
                      .join("")}
                  </ListGroupItem>
                  <ListGroupItem>
                    <strong>Number of guests:</strong>{" "}
                    {this.pluralizePeople(this.state.bookings.numberGuest)}
                  </ListGroupItem>
                </ListGroup>
                <h2>Special needs:</h2>
                <p>{this.state.bookings.specialNeeds}</p>

                <h2>Pickup location</h2>
                <ListGroup>
                  <ListGroupItem>
                    <strong>Address:</strong>{" "}
                    {this.state.bookings.pickupLocation.address}{" "}
                    {this.state.bookings.pickupLocation.city}{" "}
                    {this.state.bookings.pickupLocation.state}{" "}
                    {this.state.bookings.pickupLocation.zipcode}{" "}
                  </ListGroupItem>
                  <ListGroupItem>
                    <strong>Date/time:</strong>{" "}
                    {this.state.bookings.pickupLocation.date}
                    {" at "}
                    {this.state.bookings.pickupLocation.time}{" "}
                  </ListGroupItem>
                </ListGroup>

                {this.state.bookings.stopsQty.length && (
                  <div>
                    <h2>Stops requested</h2>
                    {this.state.bookings.stopsQty.map((item, key) => (
                      <ListGroup
                        key={key}
                        style={{ marginBottom: 30, marginLeft: 30 }}
                      >
                        <ListGroupItem>
                          <strong>Address:</strong> {item.address} {item.city}{" "}
                          {item.state} {item.zipcode}{" "}
                        </ListGroupItem>
                        <ListGroupItem>
                          <strong>Date/time:</strong> {item.date}
                          {" at "}
                          {item.time}{" "}
                        </ListGroupItem>
                      </ListGroup>
                    ))}
                  </div>
                )}

                <h2>Dropoff location</h2>
                <ListGroup>
                  <ListGroupItem>
                    <strong>Address:</strong>{" "}
                    {this.state.bookings.dropoffLocation.address}{" "}
                    {this.state.bookings.dropoffLocation.city}{" "}
                    {this.state.bookings.dropoffLocation.state}{" "}
                    {this.state.bookings.dropoffLocation.zipcode}{" "}
                  </ListGroupItem>
                  <ListGroupItem>
                    <strong>Date/time:</strong>{" "}
                    {this.state.bookings.dropoffLocation.date}
                    {" at "}
                    {this.state.bookings.dropoffLocation.time}{" "}
                  </ListGroupItem>
                </ListGroup>
              </Col>
              <Col md="4">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={sprinter}
                    alt="Card image cap"
                  />
                  <CardBody>
                    <CardTitle>
                      <h5>Cost Summary:</h5>
                    </CardTitle>
                    <CardText>
                      To secure your reservation we require 50% of the total
                      cost as a non-refundable downpayment of{" "}
                      <strong>
                        USD$
                        {this.state.bookings.downpayment}
                      </strong>
                      . This amount will be deducted from your total payment.
                    </CardText>
                    <Table size="sm">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <th scope="row">Sub-total</th>
                          <td>USD${this.state.bookings.cost}</td>
                        </tr>
                        <tr>
                          <th scope="row">Tax</th>
                          <td>
                            USD$
                            {this.state.bookings.tax}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Total</th>
                          <td>
                            USD$
                            {this.state.bookings.totalCost}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Down payment</th>
                          <td>
                            USD$
                            {this.state.bookings.downpayment}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <small className="text-muted">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={() =>
                              this.setState({
                                agreement: !this.state.agreement
                              })
                            }
                          />{" "}
                          Accept the <a href="#0">Terms of agreement</a>
                        </Label>
                      </FormGroup>
                      {this.state.agreement && (
                        <FormGroup>
                          <Elements>
                            <CheckoutForm
                              bookingId={this.state.bookingId}
                              customer={this.state.bookings.name}
                              amount={this.state.bookings.downpayment}
                            />
                          </Elements>
                        </FormGroup>
                      )}
                    </small>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </StripeProvider>
    );
  }
}

export default Checkout;
