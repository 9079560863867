import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import ServiceIcon from "../../components/serviceIcon";
import {
  InputText,
  InputNumber,
  SelectState,
  SelectDateTime,
} from "../../components/inputField";
import { Message, Loader } from "../../components/modal/modal";
import Carousel from "../../components/slider";
import StopForm from "../../components/form/stop";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./home.css";
import { states } from "../../utils";
import axios from "axios";
import moment from "moment";
// import firebase from "../../config/db";

// Database
// const db = firebase.firestore();

// email regex
// var re =
//   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Transportation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      numberGuest: 1,
      phone: "",
      specialNeeds: "",
      specialNeedsToggle: false,
      pickupLocation: {
        date: "",
        time: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
      },
      dropoffLocation: {
        date: "",
        time: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
      },
      stopsQty: [],
      maxGuest: 11,
      success: false,
      loading: false,
    };
  }

  buildTemplate = (data) => {
    let stops = ``;

    for (let stop of data.stopsQty) {
      stops += `

      STOP ${stop.index}:
      date: ${stop.date} - ${moment(stop.time, "hh:mm").format("hh:mm A")}
      ${stop.address} ${stop.city}, ${stop.state}. ${stop.zipcode}
      `;
    }

    return `
    Sprinter reservation request from https://experience.humanelevation.com on behalf of ${
      data.name
    },

    Number of guests: ${data.numberGuest}
    Phone number: ${data.phone}

    SPECIAL NEEDS:
    ${data.specialNeeds}

    PICKUP LOCATION:
    date: ${data.pickupLocation.date} - ${moment(
      data.pickupLocation.time,
      "hh:mm"
    ).format("hh:mm A")}
    ${data.pickupLocation.address} ${data.pickupLocation.city}, ${
      data.pickupLocation.state
    }. ${data.pickupLocation.zipcode}

    DROP-OFF LOCATION
    date: ${data.dropoffLocation.date} - ${moment(
      data.dropoffLocation.time,
      "hh:mm"
    ).format("hh:mm A")}
    ${data.dropoffLocation.address} ${data.dropoffLocation.city}, ${
      data.dropoffLocation.state
    }. ${data.dropoffLocation.zipcode}

    ${stops}
    `;
  };

  handleChange = (evt) =>
    this.setState({ [evt.target.name]: evt.target.value });

  handleSpecialNeed = (evt) =>
    this.setState({ [evt.target.name]: !this.state.specialNeedsToggle });

  handlePickupLocation = (evt) => {
    const pickupLocation = Object.assign({}, this.state.pickupLocation);
    pickupLocation[evt.target.name] = evt.target.value;
    this.setState({ pickupLocation });
  };
  handleDropoffLocation = (evt) => {
    const dropoffLocation = Object.assign({}, this.state.dropoffLocation);
    dropoffLocation[evt.target.name] = evt.target.value;
    this.setState({ dropoffLocation });
  };

  addStop = () =>
    this.setState({
      stopsQty: [
        ...this.state.stopsQty,
        {
          index: this.state.stopsQty.length + 1 || 1,
          address: "",
          state: "",
          city: "",
          zipcode: "",
        },
      ],
    });

  updateStop = (evt, i) => {
    const stopList = [...this.state.stopsQty];

    const stop = Object.assign({}, this.state.stopsQty[i]);
    stop[evt.target.name] = evt.target.value;

    const stopsQty = [
      ...stopList.slice(0, i),
      stop,
      ...stopList.slice(i + 1, stopList.length),
    ];

    this.setState({ stopsQty });
  };

  deleteComponent = (e, i) => {
    const stopsQty = [...this.state.stopsQty];
    stopsQty.splice(i, 1);
    this.setState({ stopsQty });
  };

  sendMail = async (formData) => {
    axios({
      method: "POST",
      url: "https://9a1wcyd351.execute-api.us-east-1.amazonaws.com/default/experience-web-contact-form",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        name: formData.name,
        email: formData.email,
        interest: "Experience booking",
        body: this.buildTemplate(formData),
      },
    })
      .catch((error) => {
        // console.log(error);
        // console.log(error);
        if (error) {
          // console(error.response.data.msg);
        } else {
          console(
            "Sorry, there was an error with the server...please try again later."
          );
        }
      })
      .then((data) => {
        this.setState({
          name: "",
          email: "",
          numberGuest: 1,
          phone: "",
          specialNeeds: "",
          specialNeedsToggle: false,
          pickupLocation: {
            date: "",
            time: "",
            address: "",
            city: "",
            state: "",
            zipcode: "",
          },
          dropoffLocation: {
            date: "",
            time: "",
            address: "",
            city: "",
            state: "",
            zipcode: "",
          },
          stopsQty: [],
          maxGuest: 11,
          success: false,
          loading: false,
        });
        this.setState({ success: true, loading: false });
      });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const formData = {
      name: this.state.name,
      email: this.state.email,
      numberGuest: this.state.numberGuest,
      phone: this.state.phone,
      specialNeeds: this.state.specialNeeds,
      pickupLocation: this.state.pickupLocation,
      dropoffLocation: this.state.dropoffLocation,
      stopsQty: this.state.stopsQty,
    };

    this.sendMail(formData);
    this.setState({ success: true, loading: false });
    // await db
    //   .collection("bookings")
    //   .add(formData)
    //   .then(async (ref) => {
    //     formData.bookingId = ref.id;
    //     try {
    //       // const response = await fetch(
    //       //   "https://impactrepublic.com/he-amenities-mail.php",

    //       //   {
    //       //     method: "POST",
    //       //     body: JSON.stringify(formData)
    //       //   }
    //       // );
    //       // await response.json();
    //       JSON.stringify(formData);
    //       this.setState({ success: true, loading: false });
    //     } catch (error) {
    //       console.error("Error:", error);
    //       this.setState({ success: false, loading: false });
    //     }
    //   });
  };
  render() {
    return (
      <main>
        {this.state.loading && <Loader />}
        {this.state.success && (
          <Message
            title="Thank you for choosing us!"
            message="Our representatives will contact you shortly"
            button="back"
            onClick={() => this.setState({ success: false })}
          />
        )}
        <Header title="LUXURY MERCEDES SPRINTER" />
        <Carousel />
        <Container>
          <div className="service-icons-container">
            <Row>
              <Col md="6">
                <Row>
                  <Col xs="3" lg="3">
                    <ServiceIcon
                      alt="high-speed-wifi"
                      label="highspeed WI-FI"
                      icon="iconWifi"
                    />
                  </Col>
                  <Col xs="3" lg="3">
                    <ServiceIcon
                      alt="Mood-light"
                      label="Mood Light"
                      icon="iconMoodlight"
                    />
                  </Col>
                  <Col xs="3" lg="3">
                    <ServiceIcon
                      alt="Private-driver"
                      label="Private Driver"
                      icon="iconPrivateDriver"
                    />
                  </Col>
                  <Col xs="3" lg="3">
                    <ServiceIcon
                      alt="Live-TV"
                      label="Live TV"
                      icon="iconliveTv"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="3" lg="3">
                    <ServiceIcon
                      alt="Restroom"
                      label="Restroom /
                      Changing room"
                      icon="iconRestroom"
                    />
                  </Col>
                  <Col xs="3" lg="3">
                    <ServiceIcon
                      alt="icon-desk"
                      label="Desk Workspace"
                      icon="iconDesk"
                    />
                  </Col>
                  <Col xs="3" lg="3">
                    <ServiceIcon
                      alt="sound-system"
                      label="Bose sound system"
                      icon="iconSoundSystem"
                    />
                  </Col>
                  <Col xs="3" lg="3">
                    <ServiceIcon
                      alt="mini-fridge"
                      label="Mini Fridge"
                      icon="miniFridge"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="1"></Col>
              <Col md="5">
                <article>
                  Human Elevation’s luxury Mercedes Sprinter is a
                  next-generation mobile workplace powered by state-of-the-art
                  technology and best-in-class amenities. This mobile office
                  offers a private bathroom, refrigerator, Apple TV, surround
                  sound system, work desk, reclining captain chairs, reclining
                  bed, and the best driver in New York. The Human
                  Elevation Sprinter is the definition of elevating work and
                  life on the go!
                </article>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <form action="#0" onSubmit={this.onSubmit}>
                  <h3>Personal information</h3>

                  <Row>
                    <Col md={3}>
                      <InputText
                        name="name"
                        label="Name"
                        placeholder="Ex. John Dough"
                        formFeedback="The name is required"
                        // invalid={true}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col md={3}>
                      <InputText
                        name="email"
                        label="Email"
                        placeholder="Ex. domain@gmail.com"
                        formFeedback="The Email is required"
                        // valid={true}
                        type="email"
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="numberGuest">Number of Guest</Label>
                        <Input
                          type="number"
                          name="numberGuest"
                          id="number-guest"
                          placeholder=""
                          onChange={this.handleChange}
                        />
                        <FormFeedback></FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="phone">Phone Number</Label>
                        <Input
                          type="number"
                          name="phone"
                          id="phone"
                          placeholder=""
                          onChange={this.handleChange}
                        />
                        <FormFeedback></FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <div className="specialneeds-checkbox">
                        <Input
                          name="specialNeedsToggle"
                          type="checkbox"
                          onChange={this.handleSpecialNeed}
                          value={this.state.specialNeedsToggle}
                        />
                        Special needs?
                      </div>
                      {this.state.specialNeedsToggle && (
                        <InputText
                          style={{ marginLeft: 5 }}
                          name="specialNeeds"
                          label=""
                          placeholder="Please let us know how can we serve you"
                          type="textarea"
                          onChange={this.handleChange}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <h3>Pickup</h3>
                      <Row>
                        <Col md={6}>
                          <SelectDateTime
                            type="date"
                            name="date"
                            label="Select Date"
                            onChange={this.handlePickupLocation}
                          />
                        </Col>
                        <Col md={6}>
                          <SelectDateTime
                            type="time"
                            name="time"
                            label="Select time"
                            onChange={this.handlePickupLocation}
                          />
                        </Col>
                        <Col md={12}>
                          <InputText
                            name="address"
                            label="Address"
                            formFeedback="The address is required"
                            onChange={this.handlePickupLocation}
                          />
                        </Col>
                        <Col md={4}>
                          <InputText
                            name="city"
                            label="City"
                            formFeedback="The city is required"
                            onChange={this.handlePickupLocation}
                          />
                        </Col>
                        <Col md={4}>
                          <SelectState
                            states={states}
                            name="state"
                            onChange={this.handlePickupLocation}
                          />
                        </Col>
                        <Col md={4}>
                          <InputNumber
                            name="zipcode"
                            label="Zipcode"
                            formFeedback="The zipcode is required"
                            onChange={this.handlePickupLocation}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6">
                      <h3>Dropoff</h3>
                      <Row>
                        <Col md={6}>
                          <SelectDateTime
                            type="date"
                            id="dropoff-date"
                            name="date"
                            label="Select Date"
                            onChange={this.handleDropoffLocation}
                          />
                        </Col>
                        <Col md={6}>
                          <SelectDateTime
                            type="time"
                            id="dropoff-time"
                            name="time"
                            label="Select time"
                            onChange={this.handleDropoffLocation}
                          />
                        </Col>
                        <Col md={12}>
                          <InputText
                            name="address"
                            id="dropoff-address"
                            label="Address"
                            formFeedback="The address is required"
                            onChange={this.handleDropoffLocation}
                          />
                        </Col>
                        <Col md={4}>
                          <InputText
                            name="city"
                            id="dropoff-city"
                            label="City"
                            formFeedback="The city is required"
                            onChange={this.handleDropoffLocation}
                          />
                        </Col>
                        <Col md={4}>
                          <SelectState
                            states={states}
                            name="state"
                            id="dropoff-state"
                            onChange={this.handleDropoffLocation}
                          />
                        </Col>
                        <Col md={4}>
                          <InputNumber
                            name="zipcode"
                            id="dropoff-zipcode"
                            label="Zipcode"
                            formFeedback="The zipcode is required"
                            onChange={this.handleDropoffLocation}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    {this.state.stopsQty.map((e, i) => {
                      return (
                        <StopForm
                          key={i}
                          qty={i + 1}
                          onRemove={(e) => this.deleteComponent(e, i)}
                          index={i}
                          updateStop={this.updateStop}
                          address={this.state.stopsQty[i].address || ""}
                          city={this.state.stopsQty[i].city || ""}
                          zipcode={this.state.stopsQty[i].zipcode || ""}
                          state={this.state.stopsQty[i].state || ""}
                          date={this.state.stopsQty[i].date || ""}
                          time={this.state.stopsQty[i].time || ""}
                        />
                      );
                    })}
                  </Row>
                  <Row>
                    <Col md={12}>
                      <a href="#0" onClick={this.addStop}>
                        Add Stop
                      </a>
                    </Col>
                  </Row>
                  <Button className="button-he" type="submit">
                    Reserve
                  </Button>
                </form>
              </Col>
            </Row>
          </div>
        </Container>
        <Footer />
      </main>
    );
  }
}

export default Transportation;
