import React from "react";
import { Button } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import "./styles.css";

export const Message = ({ title, message, button, onClick }) => (
  <div className="message-wrapper">
    <div>
      <h1>{title}</h1>
      <p>{message}</p>
      <Button className="button-he" type="button" onClick={onClick}>
        {button}
      </Button>
    </div>
  </div>
);

export const Loader = () => (
  <div className="message-wrapper">
    <ClipLoader sizeUnit={"px"} size={40} color={"#f0533f"} />
  </div>
);
