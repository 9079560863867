import React from "react";

const Footer = () => (
  <footer>
    <p>
      © Copyright {new Date().getFullYear()} -{" "}
      <strong> Human Elevation </strong>
      <a href="mailto:experience@humanelevation.com">
        experience@humanelevation.com
      </a>
    </p>
  </footer>
);

export default Footer;
