import React, { Component } from "react";
import { Col, Row, Badge } from "reactstrap";
import {
  SelectDateTime,
  InputText,
  InputNumber,
  SelectState
} from "../../inputField";
import { states } from "../../../utils";

class StopForm extends Component {
  constructor(props) {
    super(props);
    const { qty, index, onRemove, updateStop } = this.props;
    this.state = {
      index,
      qty,
      onRemove,
      updateStop
    };
  }
  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <Col md="6" className="stop-component">
        <h3>Stop {this.state.qty}</h3>
        <Badge href="#0" color="danger" onClick={e => this.props.onRemove()}>
          Remove
        </Badge>
        <Row>
          <Col md={6}>
            <SelectDateTime
              id={"input-" + this.props.index}
              name="date"
              type="date"
              label="Select Date"
              onChange={e => this.state.updateStop(e, this.state.index)}
              value={this.props.date}
            />
          </Col>
          <Col md={6}>
            <SelectDateTime
              id={"input-" + this.props.index}
              name="time"
              type="time"
              label="Select time"
              onChange={e => this.state.updateStop(e, this.state.index)}
              value={this.props.time}
            />
          </Col>
          <Col md={12}>
            <InputText
              id={"input-" + this.props.index}
              name="address"
              label="Address"
              formFeedback="The address is required"
              onChange={e => this.state.updateStop(e, this.state.index)}
              value={this.props.address}
            />
          </Col>
          <Col md={4}>
            <InputText
              id={"input-" + this.props.index}
              name="city"
              label="City"
              formFeedback="The city is required"
              onChange={e => this.state.updateStop(e, this.state.index)}
              value={this.props.city}
            />
          </Col>
          <Col md={4}>
            <SelectState
              id={"input-" + this.props.index}
              states={states}
              name="state"
              onChange={e => this.state.updateStop(e, this.state.index)}
              value={this.props.state}
            />
          </Col>
          <Col md={4}>
            <InputNumber
              id={"input-" + this.props.index}
              name="zipcode"
              label="Zipcode"
              formFeedback="The zipcode is required"
              onChange={e => this.state.updateStop(e, this.state.index)}
              value={this.props.zipcode}
            />
          </Col>
        </Row>
      </Col>
    );
  }
}

export default StopForm;
